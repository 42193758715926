import React from 'react';

const Social = ({ socialLinks }) => {
  const socialItems = socialLinks.map((social, i) => {
    return (<span> <a target="_blank" rel="noopener noreferrer" href={social.link}>{social.site} </a> {socialLinks[i + 1] ? '•' : ''} </span>)

  })
  return (
    socialItems
  );
};

export default Social;